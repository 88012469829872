import projectsDemo from '../../../videos/ProjectsDemo.mp4';
import introsDemo from '../../../videos/IntrosDemo.mp4';
import studioDemo from '../../../videos/StudioDemo.mp4';
import chessDemo from '../../../videos/Chess2Demo.mp4';
import jdxDemo from '../../../videos/jdx portfolio demo.mp4';
import capsuleDemo from '../../../videos/capsuleDemo.mp4'

export const projects = {
  capsule: {
    title: 'Capsule Studio 1.0',
    description: 'AI driven video editing platform purpose built for enterprise teams - putting dynamic motion graphics, powerful collaboration tools, and pro video polish into the hands of anyone who can operate a text document.',
    video: capsuleDemo,
    role: 'Senior Software Engineer',
    contributions: [
      'Fast-tracked the launch of Capsule Studio from beta to 1.0, delivering multiple core product features (project sharing, video exports, advanced timeline UI/UX), perfecting UI polish, squashing bugs, and optimizing code quality under aggressive deadlines',
      'Fielded critical feedback with rapid turnarounds to meet key enterprise customer needs, helping scale the company to 100+ accounts and driving substantial month-over-month growth',
      'Championed multiple organizational initiatives from the ground up, including post-launch engineering retrospectives, improved internal product education practices, elevated code documentation standards, and revamping of team member onboarding eforts',
    ],
    tools: [
      'TypeScript',
      'NextJS',
      'Redux',
      'RTKQuery',
      'Tailwind CSS',
      'Radix UI',
      'Framer Motion',
      'shadcn',
      'Payload CMS',
      'GraphQL',
      'WebRTC',
      'Figma',
    ],
    hasAudio: true,
  },
  'joint-dx': {
    title: 'JointDx',
    description: 'An AI driven orthopedic decision aid designed to help medical professionals triage patients and recommend care pathways.',
    video: jdxDemo,
    role: 'Full Stack Development Lead',
    contributions: [
      'Oversaw end-to-end platform development and deployment across the TypeScript/Python/AWS stack, including authentication, permissions, media upload, system architecture, and more',
      'Worked in close collaboration with clients and stakeholders to shape platform features, scope development, and deliver the product MVP',
      'Orchestrated comprehensive overhaul of legacy codebase, including full TypeScript migration, modernization for current best practices, and a complete dependency audit',
    ],
    tools: [
      'TypeScript',
      'Python',
      'React',
      'React Context',
      'React Query',
      'Material UI',
      'FastAPI',
      'AWS Cognito',
      'AWS DynamoDB',
      'AWS S3',
      'AWS ECS',
      'AWS EC2',
      'AWS Lambda',
      'AWS Amplify',
      'Lottie',
      'After Effects',
      'Photoshop',
      'Procreate',
      'Figma',
    ],
  },
  'chess-2': {
    title: 'Chess 2',
    description: 'A fast paced chess variant with unique rules: No turns, piece cooldown, and direct capture of the King to win. Online multiplayer supported.',
    video: chessDemo,
    role: 'Lead Engineer + Lead Designer',
    contributions: [
      'Designed application UI/UX from the ground up, including an entirely custom component library and fully original graphics',
      'Architected custom framework facilitating fast and live player to player connections for quick, responsive games',
      'Manage complete production environment and scope across both ends of the stack',
    ],
    tools: [
      'TypeScript',
      'React',
      'React Context',
      'React DnD',
      'Firebase',
      'SASS',
      'Photoshop',
      'Procreate',
      'Figma',
    ],
    link: 'https://playchess2.netlify.app/',
  },
  'clearmix-projects': {
    title: 'ClearMix Projects Platform',
    description: 'A fully featured, folder tree based user interface designed to help users ideate and plan video marketing campaigns, produce them through the ClearMix pipeline, and track them through completion.',
    video: projectsDemo,
    role: 'Lead Frontend Engineer',
    contributions: [
      'Responsible for planning, delegation, and development of platform from the ground up',
      'Architected key platform features including drag-n-drop functionality, video upload, full project customization, account balance integration, and more',
      'Developed custom wrapper hooks around React Query to allow for more performant data fetching, a DRYer codebase, and a friendlier dev experience',
    ],
    tools: [
      'TypeScript',
      'Next.js',
      'React',
      'React Query',
      'React Context',
      'React DnD',
      'AWS S3',
      'Jest',
      'React Testing Library',
      'SASS',
    ],
  },
  'clearmix-intros': {
    title: 'ClearMix Intros',
    description: 'A tool to help anyone create quick, professional, and personalized video introductions for content regardless of production or editing experience, and track their external engagement.',
    video: introsDemo,
    role: 'Frontend Engineer',
    contributions: [
      "Designed interactive landing pages for Intros content, customizable to a user's specifications - leading to thousands of Intros created and sent",
      'Contributed to key analytics tracking features, allowing users to gauge external engagement with their content and A/B test different intro styles',
    ],
    tools: [
      'TypeScript',
      'React',
      'React Context',
      'AWS S3',
      'Jest',
      'React Testing Library',
      'SASS',
      'WebRTC',
    ],
  },
  'clearmix-studio': {
    title: 'ClearMix Virtual Studio',
    description: 'A feature rich virtual recording platform built to make remote video production quick and pain-free.',
    video: studioDemo,
    role: 'Frontend Engineer',
    contributions: [
      'Developed suite of producer tools including the Notebook, allowing for the use of keyboard shortcuts for quick, integrated note-taking exported as Premiere Pro XML files',
      "Contributed to application's upload functionality, requiring the recording of high quality footage stored locally and uploaded to AWS S3 in parallel",
      'Completely refactored codebase and user interface from the ground up to bring to cleaner, more modern standards',
    ],
    tools: [
      'TypeScript',
      'React',
      'React Context',
      'Dailyjs',
      'AWS S3',
      'Jest',
      'React Testing Library',
      'SASS',
      'WebRTC',
    ],
  },
};